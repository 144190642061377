import * as Yup from 'yup';

export const profileBadgeSchema = Yup.object().shape({
  id: Yup.number().required(),
  category: Yup.string().default('').nullable(),
  asset: Yup.string().required(),
  label: Yup.string().required(),
  description: Yup.string().default('').nullable(),
  count: Yup.number().required(),
  weight: Yup.number().required(),
});

export type ProfileBadgeSchema = Yup.InferType<typeof profileBadgeSchema>;
