import React from 'react';
import { ProfilesListItem, ProfilesListItemProps, Profile } from '../../../../components/ProfilesList/components/ProfilesListItem';
import { Box } from '../../../../wag-react/core-components/box';
import { BoxProps } from '../../../../wag-react/next-components/box';
export type Props = {
  data: {
    profiles: Profile[];
  };
} & Pick<ProfilesListItemProps, 'bookNowButtonLink' | 'onClickBookNow'> & Omit<BoxProps, 'data'>;
export const ProfilesList = ({
  bookNowButtonLink,
  onClickBookNow,
  data: {
    profiles
  },
  ...rest
}: Props) => <Box {...rest} data-sentry-element="Box" data-sentry-component="ProfilesList" data-sentry-source-file="index.tsx">
    <Box m={['-4px', '-12px']} width={['calc(100% + 8px)', 'calc(100% + 24px)']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
      {profiles.map(profile => <ProfilesListItem key={profile.link} bookNowButtonLink={bookNowButtonLink} onClickBookNow={onClickBookNow} data={{
      profile
    }} location={profile.location} p={['4px', '12px']} />)}
    </Box>
  </Box>;