import * as Yup from 'yup';

import { providerProfileSchema } from './providerProfile';

export const providersByZipSchema = Yup.object().shape({
  profiles: Yup.array(providerProfileSchema).required().default([]),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zip: Yup.string().required(),
  service: Yup.string().required(),
  remote: Yup.boolean().required(),
  page: Yup.number().required(),
});

export type ProvidersByZipSchema = Yup.InferType<typeof providersByZipSchema>;
