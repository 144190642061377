'use client';

import {
  useState,
  useCallback,
} from 'react';

import * as Yup from 'yup';
import pipe from 'ramda/src/pipe';
// TODO - use date-fns instead for small footprint
import moment from 'moment';
import humps from 'humps';

import {
  WagApiEndpoint,
  WAG_API_URL,
} from '../../constants/endpoints';

import {
  CityPageDataProfiles,
} from '../../types/app';

import { providersByZipSchema } from '../../utils/validation-schemas/providersByZip';
import { formatter } from '../../utils/formatter/formatter';
import { locationParser } from '../../utils/formatter/parsers/locationParser';
import { applyPathParams } from '../../wag-react/common/api/utils';
import { httpGet } from '../../wag-react/common/api/api';

export type GetServiceProvidersByZipData = CityPageDataProfiles & {
  location: string;
};

const locationFormatter = formatter({
  parser: locationParser,
});

export type ProfilesByZipSchema = {
  city: string;
  page: number;
  service: string;
  state: string;
  zip: string;
  // TODO type correctly once Yup upgrade is complete
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profiles: any;
} & Yup.InferType<typeof providersByZipSchema>;

/**
 * Need to create a mapper because the endpoint response structure
 * is different from CityPageDataProfiles
 */
const mapToCityPageDataProfiles = ({
  city,
  page,
  profiles,
  service,
  state,
  zip,
}: ProfilesByZipSchema): GetServiceProvidersByZipData => ({
  city,
  profilesPage: page,
  service,
  state,
  hasMoreProfiles: !!profiles.length,
  location: `${city}, ${state} ${zip}`,
  profiles: profiles.map((profile) => ({
    ...profile,
    location: (profile.location && locationFormatter({
      ...profile.location,
      // States are returned as abbreviation here
      stateAbbrev: profile.location.state,
    })),
    review: profile.review && {
      ...profile.review,
      timestamp: moment(profile.review.timestamp).format('MMM D, YYYY'),
    },
    tags: profile.tags.map(({
      image: iconSrc,
      label,
    }) => ({
      iconSrc,
      label,
    })),
    services: profile.services.map(({
      image: iconSrc,
      ...rest
    }) => ({
      iconSrc,
      ...rest,
    })),
  })),
});

const castSchema = (data: unknown) => providersByZipSchema.cast(data);
const camelizeKeys = (data: Record<string, unknown>) => humps.camelizeKeys(data);

export const getServiceProvidersByZip = async ({
  zipCode,
  service = 'training',
  page,
  limit,
}: {
  zipCode: string;
  service?: string;
  page?: number;
  limit?: number;
}) => {
  const url = applyPathParams(WagApiEndpoint.GetServiceProvidersByZipCode)({
    zipCode,
    service,
  });
  const result = await httpGet<Yup.InferType<typeof providersByZipSchema>>(url, {
    baseURL: WAG_API_URL,
    params: {
      page,
      limit,
    },
  });

  const transformedData = pipe(
    camelizeKeys,
    castSchema,
    mapToCityPageDataProfiles,
  )(result.data);

  return transformedData;
};

export const useGetServiceProvidersByZip = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<GetServiceProvidersByZipData>({
    city: '',
    profilesPage: 0,
    profiles: [],
    service: '',
    state: '',
    location: '',
    hasMoreProfiles: false,
  });

  const execute = async ({
    zipCode,
    service,
    page = 0,
    limit = 5,
  }: {
    zipCode: string;
    service?: string;
    page?: number;
    limit?: number;
  }) => {
    setIsLoading(true);

    try {
      const result = await getServiceProvidersByZip({
        zipCode,
        service,
        page,
        limit,
      });

      setData(result);
      setIsLoading(false);
      return result;
    } catch (_error) {
      setError(_error);
      setIsLoading(false);
      throw error;
    }
  };

  return {
    isLoading,
    error,
    data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    execute: useCallback(execute, []),
  };
};
