'use client';

import React, { useEffect } from 'react';
import Head from 'next/head';
import { Divider } from '../../components/Divider';
import { DogTrainingHowItWorksSection } from '../../components/DogTrainingHowItWorksSection';
import { Layout } from '../../components/Layout';
import { StatesColumnListSection } from '../../components/StatesColumnListSection';
import { HeroHeaderSectionContainer } from './components/HeroHeaderSectionContainer';
import { VettedDogTrainersCarouselSection } from './components/VettedDogTrainersCarouselSection';
import { DogTrainingFaqsSection } from './components/DogTrainingFaqsSection';
import { VettedDogTrainersListSection } from './components/VettedDogTrainersListSection';
import { Profile } from '../../components/ProfilesList/components/ProfilesListItem';
import { DogTrainingServiceVideoSection } from './components/DogTrainingServiceVideoSection';
import { DogTrainingSchemas } from './components/DogTrainingSchemas';
import { useGetServiceProvidersByZip, GetServiceProvidersByZipData } from '../../hooks/api/useGetServiceProvidersByZip';
import { useGetURLQuery } from '../../hooks/useGetURLQuery';
import { tryCatch, tryCatchSync } from '../../utils/tryCatch';
import { zipCodeSchema } from '../../utils/validation-schemas/zipCode';
import { scrollToViewById } from '../../utils/scrollToView';
import { Routes, VETTED_DOG_TRAINERS_LIST_SECTION_ELEMENT_ID, WagWebAppUrl } from '../../constants';
import { QueryStringKeys } from '../../constants/query-string-keys';
export type DogTrainingServicePageValue = {
  initialServiceProviderData?: GetServiceProvidersByZipData;
};
export type DogTrainingServicePageProps = {
  value?: DogTrainingServicePageValue;
};
const scrollToVettedDogTraininersListSection = (profiles: Profile[]) => {
  if (!profiles.length) {
    return;
  }
  scrollToViewById(VETTED_DOG_TRAINERS_LIST_SECTION_ELEMENT_ID);
};
const meta = {
  title: 'Top In-Home Dog Trainers Near You | Wag!',
  description: 'Book an in-home training session with an experienced dog trainer to learn alongside your pup, promote good manners, practice new skills and more.'
};
export const DogTrainingServicePage = ({
  value: {
    initialServiceProviderData
  }
}: DogTrainingServicePageProps) => {
  const {
    isLoading,
    data,
    execute
  } = useGetServiceProvidersByZip();
  const urlQuery = useGetURLQuery();
  const hasServiceProviderProfiles = initialServiceProviderData && !!initialServiceProviderData.profiles.length || !!data.profiles.length;
  const [zipCodeFromQueryString] = tryCatchSync(() => zipCodeSchema.validateSync(urlQuery[QueryStringKeys.ZipCode]));
  const handleOnSubmit = async ({
    zipCode
  }) => {
    const [response] = await tryCatch(() => execute({
      zipCode
    }));
    scrollToVettedDogTraininersListSection(response.profiles);
  };
  const onClickBookNow = (_, profile) => {
    const href = `${WagWebAppUrl.SignupFlow}?preferred_service=14&promo_code=${profile.promoCode}&utm_campaign=pcg_selection&utm_medium=service_training&utm_source=web`;
    window.open(href, '_blank');
  };
  useEffect(() => {
    if (!initialServiceProviderData) {
      return;
    }
    scrollToVettedDogTraininersListSection(initialServiceProviderData.profiles);
  }, [initialServiceProviderData]);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="DogTrainingServicePage.tsx">
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} data-sentry-element="meta" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <meta name="robots" content="index, follow" data-sentry-element="meta" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <link rel="canonical" href="https://wagwalking.com/dog-training" />
      </Head>
      <Layout data-sentry-element="Layout" data-sentry-source-file="DogTrainingServicePage.tsx">
        <HeroHeaderSectionContainer initialValues={zipCodeFromQueryString && {
        zipCode: zipCodeFromQueryString
      }} isLoading={isLoading} onSubmit={handleOnSubmit} data-sentry-element="HeroHeaderSectionContainer" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <DogTrainingHowItWorksSection my="32px" data-sentry-element="DogTrainingHowItWorksSection" data-sentry-source-file="DogTrainingServicePage.tsx" />
        {hasServiceProviderProfiles ? <VettedDogTrainersListSection id={VETTED_DOG_TRAINERS_LIST_SECTION_ELEMENT_ID} data={initialServiceProviderData || data} onClickBookNow={onClickBookNow} /> : <VettedDogTrainersCarouselSection ctaButtonLink={Routes.DogTrainingBookNow} />}
        <DogTrainingServiceVideoSection data-sentry-element="DogTrainingServiceVideoSection" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <DogTrainingFaqsSection data-sentry-element="DogTrainingFaqsSection" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <Divider my={0} maxWidth={['80%', '75%', '85%']} data-sentry-element="Divider" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <StatesColumnListSection data-sentry-element="StatesColumnListSection" data-sentry-source-file="DogTrainingServicePage.tsx" />
        <DogTrainingSchemas meta={meta} data-sentry-element="DogTrainingSchemas" data-sentry-source-file="DogTrainingServicePage.tsx" />
      </Layout>
    </>;
};