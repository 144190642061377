import React from 'react';
import { JSONLD } from '../../../../components/JSONLD';
import { VideoLink } from '../../../../constants/app';
import { createSchemaVideo } from '../../../../seo-schemas/video';
import { createSchemaFaq } from '../../../../seo-schemas/faq';
import { trainingFaqsData } from '../../../../data/training-faqs';
import videoThumbnail from '../../../../assets/images/dog-training-video-section-desktop.jpg';
export type DogTrainingSchemasProps = {
  meta: {
    title: string;
    description: string;
  };
};
export const DogTrainingSchemas = ({
  meta
}: DogTrainingSchemasProps) => <>
    <JSONLD data-sentry-element="JSONLD" data-sentry-source-file="index.tsx">
      {createSchemaVideo({
      description: meta.description,
      name: meta.title,
      embedUrl: VideoLink.InHomeTrainingService,
      thumbnailUrl: videoThumbnail,
      uploadDate: new Date().toISOString()
    })}
    </JSONLD>
    <JSONLD data-sentry-element="JSONLD" data-sentry-source-file="index.tsx">
      {createSchemaFaq({
      entities: trainingFaqsData.map(faqData => ({
        question: faqData.header,
        answer: Array.isArray(faqData.body) ? faqData.body.join(' ') : faqData.body
      }))
    })}
    </JSONLD>
  </>;