import {
  titleCase,
  upperCase,
} from 'voca';

import { ParserFunction } from '../type';

export const locationParser: ParserFunction = ({
  city,
  state,
  stateAbbrev,
  zip = '',
}) => `${titleCase(city)}, ${upperCase(stateAbbrev) || titleCase(state)} ${zip}`.trim();
