import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Props as HowItWorksSectionProps, HowItWorksSectionItemProp } from '../HowItWorksSection';
import { SectionHeading } from '../SectionHeading';
import { Carousel } from '../Carousel';
import { IllustrationAndText } from '../IllustrationAndText/v2';
import illustrationFindYourMatch from '../../assets/illustrations/find-your-match.svg';
import illustrationMeetAndGreet from '../../assets/illustrations/meet-and-greet.svg';
import illustrationPractice from '../../assets/illustrations/practice-makes-perfect.svg';
import { Container } from '../../wag-react/components/Container';
import { theme } from '../../wag-react/themes/base-theme';
import { Box } from '../../wag-react/next-components/box';
import { UL } from '../../wag-react/core-components/ul';
const HOW_IT_WORKS_ITEMS: HowItWorksSectionItemProp = [{
  description: 'Browse local dog trainer profiles and select your favorites so we understand your preferences',
  title: 'Find your match',
  illustration: illustrationFindYourMatch,
  illustrationAlt: 'browse local dog trainers illustration'
}, {
  description: 'Meet your dog trainer! Start creating a unique plan over in-app chat before your first session',
  illustration: illustrationMeetAndGreet,
  title: 'Meet & greet',
  illustrationAlt: 'meet your experienced dog trainer'
}, {
  description: 'After the meet & greet, request your trainer again, or work with someone new',
  illustration: illustrationPractice,
  title: 'Practice makes perfect',
  illustrationAlt: 'continuous dog training illustration'
}];
const swiperParams = {
  pagination: true
};
export type Props = Omit<HowItWorksSectionProps, 'items'>;
export const DogTrainingHowItWorksSection = (props: Props) => <Container id="how-it-works-section" as="section" {...props} data-sentry-element="Container" data-sentry-component="DogTrainingHowItWorksSection" data-sentry-source-file="index.tsx">
    <SectionHeading fontFamily="muliBold" color={theme.colors.charcoal} data-sentry-element="SectionHeading" data-sentry-source-file="index.tsx">
      How to book dog trainers near me
    </SectionHeading>

    <SwiperStyles data-sentry-element="SwiperStyles" data-sentry-source-file="index.tsx" />

    <Carousel display={['block', 'none']} value={HOW_IT_WORKS_ITEMS} swiperParams={swiperParams} data-sentry-element="Carousel" data-sentry-source-file="index.tsx">
      {({
      title,
      description,
      illustration
    }: typeof HOW_IT_WORKS_ITEMS[0]) => <Box>
          <IllustrationAndText key={title} as="li" titleText={title} titleTextProps={{
        fontFamily: 'muliBold',
        color: theme.colors.charcoal
      }} descriptionText={description} illustrationSrc={illustration} illustrationAlt={title} illustrationProps={{
        height: ['70px', '90px'],
        width: ['70px', '90px']
      }} gridTemplateColumns={['70px 1fr', '90px 1fr']} gridGap="24px" mt="32px" {...{
        as: 'li'
      }} />
        </Box>}
    </Carousel>

    <UL display={['none', 'flex']} flexDirection={['column', null, 'row']} justifyContent="space-around" mt={[0, null, '32px']} px={0} data-sentry-element="UL" data-sentry-source-file="index.tsx">
      {HOW_IT_WORKS_ITEMS.map(({
      title,
      description,
      illustration
    }) => <IllustrationAndText key={title} as="li" titleText={title} titleTextProps={{
      fontFamily: 'muliBold',
      color: theme.colors.charcoal
    }} descriptionText={description} illustrationSrc={illustration} illustrationAlt={title} illustrationProps={{
      height: ['70px', '90px'],
      width: ['70px', '90px']
    }} gridTemplateColumns={['70px 1fr', '90px 1fr']} gridGap="24px" mt="32px" {...{
      as: 'li'
    }} />)}
    </UL>
  </Container>;
const SwiperStyles = createGlobalStyle`
  /* To avoid conflicts with other sections containing swiper too */
  #how-it-works-section .swiper-container {
    --swiper-pagination-color: ${theme.colors.caribbeanGreen};
    height: 200px;
  }
`;