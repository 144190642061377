import styled from 'styled-components';
import { background, border, color, compose, flexbox, grid, layout, position, shadow, space, system, typography } from 'styled-system';
const cursorStyleFn = system({
  cursor: true
});
const styleProps = compose(background, border, color, cursorStyleFn, flexbox, grid, layout, position, shadow, space, typography);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Input = styled.input<any>`
  ${styleProps}
`;