/* eslint-disable react/display-name */
import React, { ComponentType, forwardRef, MutableRefObject } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../../next-components/box';
import { Flex } from '../../next-components/Flex';
import { Input } from '../input';
import { HelperText, HelperTextProps } from './HelperText';
type Variant = 'outlined' | 'filled';
type HTMLInputElement = JSX.IntrinsicElements['input'];
export type Props = {
  label: string;
  name?: string;
  variant?: Variant;
  fullWidth?: boolean;
  inputProps?: Omit<HTMLInputElement, 'value'> & BoxProps & {
    startAdornment?: ComponentType<React.PropsWithChildren<unknown>>;
    endAdornment?: ComponentType<React.PropsWithChildren<unknown>>;
  };
  containerProps?: BoxProps;
  ref?: MutableRefObject<HTMLInputElement>;
  error?: boolean;
  helperText?: string;
  helperTextProps?: Omit<HelperTextProps, 'text' | 'error'>;
} & HTMLInputElement;
const applyErrorStyleIfExists = (error: boolean) => {
  if (!error) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'red'
  };
};

// TODO Create a styled-system for this
const StyledInput = styled(Input)`
  outline: none;

  ::placeholder {
    color: #aaaaaa;
    /*
      Chrome don't have padding by default
      Safari mobile includes padding by default
    */
    padding: 0;
  }
`;
const Base = forwardRef<HTMLInputElement, Props>(({
  label,
  value,
  fullWidth,
  inputProps: {
    startAdornment: StartAdornmentComponent,
    endAdornment: EndAdornmentComponent,
    ...restInputProps
  } = {
    startAdornment: null,
    endAdornment: null
  },
  containerProps,
  ...rest
}, ref) => <Flex borderWidth="1px" borderStyle="solid" borderColor="lightGrey" {...containerProps} display={fullWidth ? 'flex' : 'inline-flex'} borderRadius="8px" p={2}>
      {StartAdornmentComponent && <Flex pr="8px">
          <StartAdornmentComponent />
        </Flex>}

      <StyledInput flex={1} fontFamily="muli" fontSize={4} value={value} color="medGray" p={0} {...restInputProps} border="none" {...rest} ref={ref} placeholder={label} />

      {EndAdornmentComponent && <Flex pl="8px">
          <EndAdornmentComponent />
        </Flex>}
    </Flex>);
const variantMapping = {
  filled: forwardRef<HTMLInputElement, Props>(({
    containerProps,
    error,
    inputProps,
    ...rest
  }, ref) => <Base containerProps={{
    borderStyle: 'none',
    ...applyErrorStyleIfExists(error),
    backgroundColor: 'backgroundGray',
    ...containerProps
  }} inputProps={{
    backgroundColor: 'backgroundGray',
    ...inputProps
  }} ref={ref} {...rest} />),
  outlined: forwardRef<HTMLInputElement, Props>((props, ref) => <Base ref={ref} containerProps={{
    ...applyErrorStyleIfExists(props.error),
    ...props.containerProps
  }} {...props} />)
};
export const TextField = forwardRef<HTMLInputElement, Props>(({
  variant = 'outlined',
  helperText,
  helperTextProps,
  ...rest
}, ref) => {
  const Component = variantMapping[variant];
  return <Box>
        <Component ref={ref} {...rest} />
        <HelperText text={helperText} error={rest.error} {...helperTextProps} />
      </Box>;
});
TextField.displayName = 'TextField';
export type TextFieldProps = Props;