import React from 'react';
import * as Yup from 'yup';
import { useFormik, FormikConfig } from 'formik';
import { HeroHeaderSection } from '../../../../components/HeroHeaderSection';
import { Tooltip } from '../../../../components/Tooltip';
import { getFormikFieldHelper } from '../../../../utils/getFormikFieldHelper';
import { zipCodeSchema, isValidZipCode } from '../../../../utils/validation-schemas/zipCode';
import { Routes } from '../../../../constants/routes';
import dogTrainingHeroHeaderBackgroundDesktop from '../../../../assets/hero-header-backgrounds/dog-training-desktop.jpg';
import dogTrainingHeroHeaderBackgroundMobile from '../../../../assets/hero-header-backgrounds/dog-training-mobile.jpg';
import dogTrainingHeroHeaderBackgroundTablet from '../../../../assets/hero-header-backgrounds/dog-training-tablet.jpg';
import { Flex } from '../../../../wag-react/next-components/Flex';
import { TextField } from '../../../../wag-react/core-components/textfield';
import { Button } from '../../../../wag-react/next-components/button';
enum FormField {
  ZipCode = 'zipCode',
}
const schema = Yup.object().shape({
  [FormField.ZipCode]: Yup.lazy((value: string) => {
    if (!value) {
      return Yup.mixed().notRequired();
    }
    return zipCodeSchema.test('is-valid', 'Oops! We’ll need a valid zip code to find trainers near you. Try again.', isValidZipCode);
  })
});
type Props = Pick<FormikConfig<Yup.InferType<typeof schema>>, 'onSubmit'> & Partial<Pick<FormikConfig<Yup.InferType<typeof schema>>, 'initialValues'>> & {
  isLoading: boolean;
};
export const HeroHeaderSectionContainer = ({
  initialValues,
  isLoading,
  onSubmit
}: Props) => {
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    values
  } = useFormik({
    validationSchema: schema,
    // Unable to put `schema.cast` in the default values
    initialValues: initialValues || schema.cast(),
    onSubmit
  });
  const formikFieldHelper = getFormikFieldHelper({
    errors,
    touched
  });
  return <HeroHeaderSection backgroundImage={[`url(${dogTrainingHeroHeaderBackgroundMobile})`, `url(${dogTrainingHeroHeaderBackgroundTablet})`, `url(${dogTrainingHeroHeaderBackgroundDesktop})`]} height={['544px', '368px', '548px']} subtitleText="Personalized one-on-one training sessions with a 5-star dog trainer" titleText="Trusted in-home dog training" titleTextProps={{
    maxWidth: ['268px', '465px']
  }} ctaBlock={<form onSubmit={handleSubmit}>
          <Flex alignItems="center" my="24px">
            <Tooltip text={formikFieldHelper(FormField.ZipCode).errorMessage} show={formikFieldHelper(FormField.ZipCode).hasError} width="200px" left="30%">
              <TextField label="Zip Code" name="zipCode" variant="filled" maxLength={5} containerProps={{
          backgroundColor: 'white',
          width: ['152px', '184px']
        }} inputProps={{
          width: 1,
          fontSize: ['14px', '16px'],
          backgroundColor: 'white'
        }} error={formikFieldHelper(FormField.ZipCode).hasError} value={values[FormField.ZipCode]} onBlur={handleBlur} onChange={handleChange} />
            </Tooltip>
            <Button mx={['12px', '24px']} variant="contained" disabled={!isValid || isLoading} href={values[FormField.ZipCode] ? null : Routes.DogTrainingFindMyTrainerLink} type={values[FormField.ZipCode] ? 'submit' : 'button'}>
              Find my trainer
            </Button>
          </Flex>
        </form>} data-sentry-element="HeroHeaderSection" data-sentry-component="HeroHeaderSectionContainer" data-sentry-source-file="HeroHeaderSectionContainer.tsx" />;
};