import * as Yup from 'yup';

export const profileServiceSchema = Yup.object().shape({
  image: Yup.string().required(),
  slug: Yup.string().required(),
  label: Yup.string().required(),
  isChecked: Yup.boolean().required(),
});

export type ProfileServiceSchema = Yup.InferType<typeof profileServiceSchema>;
