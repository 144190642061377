import * as Yup from 'yup';

import { tagSchema } from './tag';
import { profileServiceSchema } from './profileService';
import { profileFieldSchema } from './profileField';
import { profileBadgeSchema } from './profileBadge';
import { profileLocationSchema } from './profileLocation';

export const profileSchema = Yup.object({
  link: Yup.string().required(),
  isOptIn: Yup.boolean().required(),
  name: Yup.string().required(),
  location: profileLocationSchema.required().default(null).nullable(),
  rating: Yup.number().required(),
  ratingCount: Yup.number().required(),
  servicesCount: Yup.number().required(),
  catchphrase: Yup.string().required(),
  picture: Yup.string().required(),
  thumbnail: Yup.string().required(),
  tags: Yup.array(tagSchema).required(),
  bio: Yup.string().required(),
  video: Yup.string().notRequired().default(''),
  services: Yup.array(profileServiceSchema).required(),
  fields: Yup.array(profileFieldSchema).required(),
  badges: Yup.array(profileBadgeSchema).required(),
  promoCode: Yup.string().required(),
  lastServiceCompletedAt: Yup.string().default('').nullable(),
  uuid: Yup.string().required(),
}).required();

export type ProfileSchema = Yup.InferType<typeof profileSchema>;
