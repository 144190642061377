import React from 'react';
import { ContainerProps, Container } from '../../../../components/Container';
import { SectionHeading } from '../../../../components/SectionHeading';
import { ProfilesList, Props as ProfilesListProps } from '../ProfileList';
import { Routes } from '../../../../constants';
import { Flex } from '../../../../wag-react/next-components/Flex';
import { Button } from '../../../../wag-react/next-components/button';
export type Props = Pick<ProfilesListProps, 'bookNowButtonLink' | 'data' | 'onClickBookNow'> & Omit<ContainerProps, 'children' | 'data'>;
export const VettedDogTrainersListSection = ({
  bookNowButtonLink,
  onClickBookNow,
  data,
  ...rest
}: Props) => <Container pb={['16px', '96px']} pt={['32px', '72px']} {...rest} data-sentry-element="Container" data-sentry-component="VettedDogTrainersListSection" data-sentry-source-file="index.tsx">
    <SectionHeading data-sentry-element="SectionHeading" data-sentry-source-file="index.tsx">
      Vetted dog trainers
    </SectionHeading>
    <ProfilesList bookNowButtonLink={bookNowButtonLink} onClickBookNow={onClickBookNow} data={data} maxWidth="876px" mt={['24px', '40px']} mx="auto" data-sentry-element="ProfilesList" data-sentry-source-file="index.tsx" />
    <Flex justifyContent="center" alignItems="center" flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
      <Button href={Routes.DogTrainingFindMoreTrainers} mt="16px" mx="auto" variant="outlined" data-sentry-element="Button" data-sentry-source-file="index.tsx">
        Find more trainers
      </Button>
    </Flex>
  </Container>;