export type Params = {
  name: string;
  description: string;
  thumbnailUrl: string;
  embedUrl: string;
  uploadDate: string;
};
export const createSchemaVideo = ({
  name,
  description,
  embedUrl,
  thumbnailUrl,
  uploadDate,
}: Params) => ({
  '@context': 'https://schema.org',
  '@type': 'VideoObject',
  name,
  description,
  thumbnailUrl,
  uploadDate,
  embedUrl,
});
