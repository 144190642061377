import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../../wag-react/next-components/box';
import { Typography } from '../Typography';
type Props = BoxProps & {
  text: string;
  children: ReactNode;
  show?: boolean;
};

// TODO - Let's improve our Tooltip component using this https://github.com/ReactTooltip/react-tooltip as the base
// TOOO - Support different colors, placements, and etc
// TODO - Move to wag-react
const TypographyArrow = styled(Typography)`
  &:before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #FF5867 transparent;
  }
`;
export const Tooltip = ({
  text,
  children,
  show,
  ...rest
}: Props) => <Box position="relative" display="inline-block" data-sentry-element="Box" data-sentry-component="Tooltip" data-sentry-source-file="index.tsx">
    {children}
    {show && <TypographyArrow variant="body2" fontSize="14px" width="120px" backgroundColor="#FF5867" color="#fff" textAlign="center" borderRadius="6px" padding="8px 0" position="absolute" top="150%" left="50%" marginLeft="-60px" css={{
    visibility: 'visible',
    zIndex: 1
  }} {...rest}>
        {text}
      </TypographyArrow>}
  </Box>;