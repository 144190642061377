import petAvatar01 from '../assets/icons/dog-avatar-1.jpg';
import petAvatar02 from '../assets/icons/dog-avatar-2.jpg';
import petAvatar03 from '../assets/icons/dog-avatar-3.jpg';
import petAvatar04 from '../assets/icons/dog-avatar-4.jpg';
import petAvatar05 from '../assets/icons/dog-avatar-5.jpg';
import petAvatar06 from '../assets/icons/dog-avatar-6.jpg';

export const inHomeTrainingReviews = [
  {
    rating: 5,
    name: 'Kevin M',
    src: petAvatar01,
    review:
      'Wag! is a great service. It\'s personable and easy to use! Most of my experiences have been great and the people on here love dogs and do an amazing job!',
  },
  {
    rating: 5,
    name: 'Jessica G',
    src: petAvatar02,
    review:
      'Madison had helpful tips to help curb my little mutt\'s behavioral issues, such as resource guarding and leash pulling. Thanks Madison!',
  },
  {
    rating: 5,
    name: 'Sarah K',
    src: petAvatar03,
    review:
      'Brendan was so helpful helping me with Miss Honey\'s nipping problem. I\'m already seeing improvement with his advice, and I feel a TON more confident knowing I\'m on the right track!',
  },
  {
    rating: 5,
    name: 'Susan C',
    src: petAvatar04,
    review: 'My trainer\'s tips helped a lot and my two dogs are doing so much better now. No more anxiety doing chores while they are playing together. Coming back soon to do some trick training!',
  },
  {
    rating: 5,
    name: 'Tabatha S',
    src: petAvatar05,
    review:
      'The trainer helped me work with Tesla on his manners! Very thorough and I appreciate the visual examples with the trainer\'s own pup!',
  },
  {
    rating: 5,
    name: 'Shannon L',
    src: petAvatar06,
    review:
      'Kelly was very helpful!! She gave very clear advice and showed a lot of care and interest in Willie. Definitely would love to work with her again!',
  },
];
