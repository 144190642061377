export const trainingFaqsData = [
  {
    header: 'What is Wag!?',
    body:
      'Wag! is the #1 app for pet parents -- offering 5-star dog walking, pet sitting, veterinary care, and training services nationwide. Book convenient pet care in your neighborhood with the Wag! app. Whether you’re looking for daily walks, planning a trip, stuck at work, or just want your best friend to have some company - any day, anytime pet care is available through the app.',
  },
  {
    header: 'How do Wag! in-home training services work?',
    body:
      'In a 60-minute at-home training session your pup can learn how to follow commands and gain new skills with the help of a local dog trainer. The hands-on sessions allow you to participate too so you can learn as much as your dog, helping to reinforce a daily routine and promote good behaviors both in and outside of your home. You can request an initial training session or book your favorite trainer again within the app. Training sessions are available between 8 AM and 8:30 PM, 7 days a week.',
  },
  {
    header: 'Who’s training my dog?',
    body:
      'Wag! connects you with experienced dog trainers in your neighborhood. Your highly-rated in-home dog trainer will work with you and your pup to meet your goals. Start by creating a unique plan over in-app chat with your trainer before your first session.',
  },
  {
    header: 'Is Wag! right for my dog?',
    body: [
      'The Wag! matchmaking process is designed to find your perfect match. On Wag! you can find and book a pet caregiver with experience caring for puppies, older dogs, dogs with behavioral issues, dogs with separation anxiety, and common commands and tricks. Being upfront about any special requirements is the best way to find the perfect match for your dog.',
    ],
  },
  {
    header: 'How much does a Wag! in-home training service cost?',
    body: [
      'On average, the cost of a 60 minute In-Home Training session is $61 - $66*. To receive the best experience, training sessions are meant for one dog. The estimated rate will be displayed at the bottom of the screen during booking, with a final price provided when you match with a Pet Caregiver.',
      '*These are estimated prices. The final price may be higher or lower.',
    ],
  },
  {
    header: 'How do I evaluate dog trainers with Wag?',
    body:
      'Browse local dog trainer profiles and select your favorites so we understand your preferences.',
  },
  {
    header: 'How do I pay for the service?',
    body:
      'Wag! accepts all major credit cards, including VISA, MasterCard and American Express. Never pay by cash or check as this makes your booking ineligible for premium insurance and support.',
  },
];
