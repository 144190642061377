import { parseUrl } from 'query-string';

import { useRouter } from './useRouter';

export const useGetURLQuery = () => {
  const {
    location: {
      href,
    } = {
      href: '',
    },
  } = useRouter();
  return parseUrl(href).query as Record<string, string>;
};
