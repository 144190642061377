import styled from 'styled-components';
import { background, border, color, compose, flexbox, grid, layout, position, shadow, space, typography } from 'styled-system';
import { StyledSystemProps } from '../../types';
const styleProps = compose(background, border, color, flexbox, grid, layout, position, shadow, space, typography);
export type DividerProps = StyledSystemProps;
export const Divider = styled.hr.attrs<DividerProps>(({
  bg
}: DividerProps) => ({
  bg: bg || 'lightGrey',
  border: 'none',
  height: '1px'
}))<DividerProps>(styleProps);