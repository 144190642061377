import { FormikState } from 'formik';

type FormikFieldHelper<T> = Partial<FormikState<T>>;

export const getFormikFieldHelper = <T>({
  errors,
  touched,
}: FormikFieldHelper<T>) => (fieldName: string) => ({
    hasError: touched[fieldName] && Boolean(errors[fieldName]),
    errorMessage:
    touched[fieldName] && errors[fieldName] ? String(errors[fieldName]) : '',
  });
