import React from 'react';
import { BackgroundProps, FlexboxProps, LayoutProps, SpaceProps, BackgroundColorProps, BorderProps, ColorProps, GridProps, PositionProps, ShadowProps, TypographyProps } from 'styled-system';
import { Typography } from '../../components/Typography';
type StyledSystemProps = BackgroundProps & BackgroundColorProps & BorderProps & ColorProps & FlexboxProps & GridProps & LayoutProps & PositionProps & ShadowProps & SpaceProps & TypographyProps;
type Props = StyledSystemProps & {
  text: string;
  error?: boolean;
};
export const HelperText = ({
  text,
  error,
  ...rest
}: Props) => <>
    {text && <Typography variant="legal" color={error ? 'red' : 'initial'} {...rest}>
        {text}
      </Typography>}
  </>;
export type HelperTextProps = Props;