import * as Yup from 'yup';

export const profileFieldTagSchema = Yup.object().shape({
  slug: Yup.string().required(),
  label: Yup.string().required(),
  description: Yup.string().required(),
  isChecked: Yup.boolean().required(),
});

export type ProfileFieldTagSchema = Yup.InferType<typeof profileFieldTagSchema>;
