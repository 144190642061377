import * as Yup from 'yup';

export const reviewSchema = Yup.object().shape({
  dogId: Yup.number().required(),
  name: Yup.string().required(),
  picture: Yup.string().required(),
  comment: Yup.string().required(),
  rating: Yup.number().required(),
  timestamp: Yup.string().required(),
  type: Yup.string().required(),
});

export type ReviewSchema = Yup.InferType<typeof reviewSchema>;
