import * as Yup from 'yup';

import { profileSchema } from '../walkerProfile/profile';
import { reviewSchema } from '../walkerProfile/review';
import { providerProfileLocationSchema } from './providerProfileLocation';

export const providerProfileSchema = Yup.object().shape({
  ...profileSchema.fields,
  location: providerProfileLocationSchema.required().nullable(),
  review: reviewSchema.required().nullable(),
});

export type ProviderProfileSchema = Yup.InferType<typeof providerProfileSchema>;
