import React, { ReactNode } from 'react';
import { Image, ImageProps } from '../../../wag-react/next-components/image';
import { Typography } from '../../../wag-react/components/Typography';
import { Flex, FlexProps } from '../../../wag-react/next-components/Flex';
import { BoxProps } from '../../../wag-react/next-components/box';
import { Grid, GridProps } from '../../../wag-react/core-components/grid';
export type IllustrationAndText = GridProps & {
  descriptionText?: string | ReactNode;
  descriptionTextProps?: BoxProps;
  illustrationSrc: string;
  illustrationAlt?: string;
  illustrationProps?: ImageProps;
  titleText?: string;
  titleTextProps?: BoxProps;
  textContainerProps?: FlexProps;
};
export const IllustrationAndText = ({
  descriptionText,
  descriptionTextProps,
  illustrationSrc,
  illustrationAlt = 'illustration',
  illustrationProps,
  titleText,
  titleTextProps,
  textContainerProps,
  ...rest
}: IllustrationAndText) => <Grid gridTemplateColumns="60px 1fr" alignItems="flex-start" gridGap="12px" width={['100%', '524px', '356px']} {...rest} data-sentry-element="Grid" data-sentry-component="IllustrationAndText" data-sentry-source-file="index.tsx">
    <Image height="60px" width="60px" src={illustrationSrc} alt={illustrationAlt} {...illustrationProps} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    <Flex flexDirection="column" {...textContainerProps} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
      <Typography fontWeight="bold" m={0} variant="body1" fontFamily="muliBold" {...titleTextProps} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {titleText}
      </Typography>

      {typeof descriptionText === 'string' ? <Typography mb={0} mt="16px" variant="body1" {...descriptionTextProps}>
          {descriptionText}
        </Typography> : <>
          {descriptionText}
        </>}
    </Flex>
  </Grid>;