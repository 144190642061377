import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { createGlobalStyle } from 'styled-components';
import { CarouselNavigationButton, CarouselNavigationButtonProps } from '../CarouselNavigationButton';
import { useTheme } from '../../hooks/useTheme';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { pxToNumber } from '../../wag-react/common/utils/pxToNumber';
import { Box, BoxProps } from '../../wag-react/next-components/box';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// Unable to do type composition of swiper options
SwiperCore.use([Navigation, Pagination]);
type SwiperParams = Record<string, unknown>;
export const mobileSwiperParams: Partial<SwiperParams> = {
  freeMode: true,
  slidesOffsetBefore: 16,
  spaceBetween: 16,
  width: 264,
  slidesPerView: 1.3
};
export const desktopSwiperParams: Partial<SwiperParams> = {
  slidesPerView: 2,
  grabCursor: true,
  loop: true,
  loopFillGroupWithBlank: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  spaceBetween: 16
};
export type CarouselProps = Omit<BoxProps, 'value' | 'children'> & {
  value?: unknown[];
  swiperParams?: Partial<SwiperParams> | Partial<SwiperParams>[];
  prevButtonProps?: BoxProps & {
    icon?: CarouselNavigationButtonProps['icon'];
  };
  nextButtonProps?: BoxProps & {
    icon?: CarouselNavigationButtonProps['icon'];
  };
  children?: (item, index?: number) => JSX.Element;
  navigation?: boolean;
  // Used to extract unique key to a given item
  keyExtractor?: (item: unknown) => string;
};
// Navigation defaults to true unless overridden as false by parent element
export const Carousel = ({
  value,
  swiperParams = [mobileSwiperParams, desktopSwiperParams],
  children,
  prevButtonProps,
  nextButtonProps,
  navigation = true,
  keyExtractor,
  ...rest
}: CarouselProps): JSX.Element => {
  const {
    theme
  } = useTheme();
  const smBreakpoint = pxToNumber(theme.breakpoints[0]);
  const {
    matched: isXsDown
  } = useMediaQuery({
    maxWidth: smBreakpoint - 1
  });
  const getSwiperProps = () => {
    // Copy options to avoid direct mutations
    const copiedSwiperParams = Array.isArray(swiperParams) ? [...swiperParams] : swiperParams;
    if (Array.isArray(copiedSwiperParams)) {
      return isXsDown ? copiedSwiperParams.shift() : copiedSwiperParams.pop();
    }
    return copiedSwiperParams;
  };
  const swiperProps = getSwiperProps();
  return <>
      <Box position="relative" px={[0, '48px', '56px']} {...rest} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {/*
          We can't create a component for the "children" of the <Swiper />
          for some reason with its internal code. Whenever we create
          a component and render it on Swiper's children,
          the CSS classes aren't getting applied.
          In order to work on top of that, we have to iterate here directly
         */}
        <Swiper {...swiperProps} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">

          <CarouselNavigationButton variant="prev" icon={prevButtonProps && prevButtonProps.icon} data-sentry-element="CarouselNavigationButton" data-sentry-source-file="index.tsx" />
          <CarouselNavigationButton variant="next" icon={nextButtonProps && nextButtonProps.icon} data-sentry-element="CarouselNavigationButton" data-sentry-source-file="index.tsx" />

          {value.map((item, index) => <SwiperSlide key={keyExtractor ? keyExtractor(item) : String(item)}>
              {children(item, index)}
            </SwiperSlide>)}
        </Swiper>
        {/* Hacky workaround adding this extra `Box` component with the
         `swiper-button-prev/next` className and id is the only way the the
         `<CarouselNavigationButton/>` component  will show up. */}
        {!isXsDown && navigation && <Box className="swiper-button-prev" height="auto" id="swiper-button-prev" left={[0, 0, '8px']} width="auto" {...prevButtonProps} />}
        {!isXsDown && navigation && <Box className="swiper-button-next" height="auto" id="swiper-button-next" right={[0, 0, '8px']} width="auto" {...nextButtonProps} />}
      </Box>
      <SwiperStyles data-sentry-element="SwiperStyles" data-sentry-source-file="index.tsx" />
    </>;
};
const SwiperStyles = createGlobalStyle`
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next:after {
    display: none;
  }
`;