import * as Yup from 'yup';

import { profileFieldTagSchema } from './profileFieldTag';

export const profileFieldSchema = Yup.object().shape({
  title: Yup.string().required(),
  id: Yup.string().required(),
  tags: Yup.array(profileFieldTagSchema).required(),
});

export type ProfileFieldSchema = Yup.InferType<typeof profileFieldSchema>;
