import * as Yup from 'yup';

export const isValidZipCode = (zipCode: string) => {
  const zipCodeRegex = /^\d{5}$/;
  return zipCodeRegex.test(zipCode);
};

export const zipCodeSchema = Yup.string()
  .required()
  .default('')
  .label('Zip Code')
  .test(
    'is-valid',
    'Invalid zip code',
    isValidZipCode,
  );
