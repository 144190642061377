import React from 'react';
import { DogTrainingServicePage, DogTrainingServicePageValue } from '../../containers/DogTrainingServicePage';
import { getServiceProvidersByZip } from '../../hooks/api/useGetServiceProvidersByZip';
import { tryCatch } from '../../utils/tryCatch';
import { requestHandler } from '../../utils/requestHandler';
import { QueryStringKeys } from '../../constants/query-string-keys';
import { DEFAULT_CACHE_POLICY } from '../../constants/cache';
import { HttpHeaderKey } from '../../constants/http-header-key';
type Props = {
  value: Pick<DogTrainingServicePageValue, 'initialServiceProviderData'>;
};
const index = ({
  value: {
    initialServiceProviderData
  }
}: Props) => <DogTrainingServicePage value={{
  initialServiceProviderData
}} data-sentry-element="DogTrainingServicePage" data-sentry-component="index" data-sentry-source-file="index.tsx" />;
const handleGetServerSideProps = requestHandler({
  headers: {
    [HttpHeaderKey.CacheControl]: DEFAULT_CACHE_POLICY
  }
});
export const getServerSideProps = handleGetServerSideProps(async ({
  query
}) => {
  const zipCode = String(query[QueryStringKeys.ZipCode]);
  if (!zipCode) {
    return {
      props: {
        value: {
          initialServiceProviderData: null
        }
      }
    };
  }
  const [response] = await tryCatch(() => getServiceProvidersByZip({
    zipCode,
    page: 0,
    limit: 5
  }));
  return {
    props: {
      value: {
        initialServiceProviderData: response
      }
    }
  };
});
export default index;